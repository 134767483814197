/* src/App.css */

/* Apply background colors to body instead of .app-container */
body.light-mode {
  background-color: #fff;
  color: #000;
}

body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* Remove background color from .app-container */
.app-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  /* Keep max-width and margin to center content */
  max-width: 1200px;
  margin: 0 auto;
}

/* Update other styles that relied on .light-mode and .dark-mode being on .app-container */
body.dark-mode .attraction-row {
  border-bottom: 1px solid #333;
}

body.dark-mode .attraction-row:hover {
  background-color: #1e1e1e;
}

body.dark-mode .slider {
  background-color: #757575;
}

body.dark-mode input:checked + .slider {
  background-color: #81d4fa;
}

body.dark-mode .toggle-label {
  color: #e0e0e0;
}

body.dark-mode .park-button.selected {
  border-color: #81d4fa;
}

body.dark-mode .app-footer a:hover {
  color: #bbb;
}

body.light-mode .attraction-row:hover {
  background-color: #f9f9f9;
}

/* Ensure that the body, html, and app-container fill the full height */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.app-container {
  min-height: 100%;
}

/* Header and Top Controls */
.app-header {
  text-align: center;
  position: relative;
}

.top-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}

@media (max-width: 600px) {
  .app-header {
    text-align: center;
  }

  .top-controls {
    position: static;
    margin-top: 10px;
    justify-content: center;
  }
}

/* Attraction Counter */
.attraction-counter {
  text-align: center;
  margin: 10px 0;
}

body.dark-mode .attraction-counter {
  color: #e0e0e0;
}

body.light-mode .attraction-counter {
  color: #000;
}

.refresh-button {
  font-size: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  background-color: white;
  left: 4px;
  bottom: 4px;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  margin-left: 10px;
}

.park-selector {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 10px;
}

.park-button {
  font-size: 24px;
  padding: 10px;
  margin: 0 5px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.park-button.selected {
  border-color: blue;
}

.today-hours {
  text-align: center;
  margin-bottom: 20px;
}

.today-hours h3 {
  margin-bottom: 10px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-sort {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.filter-sort label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-sort select {
  padding: 5px;
}

.favorites-toggle {
  text-align: center;
  margin-bottom: 10px;
}

.attractions-list {
  display: flex;
  flex-direction: column;
}

.loading,
.error-message {
  text-align: center;
  margin: 20px 0;
}

.app-footer {
  text-align: center;
  margin-top: 20px;
}

.app-footer a {
  color: inherit;
  text-decoration: underline;
}

.app-footer a:hover {
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-sort {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Adjustments for small screens */
@media (max-width: 600px) {
  .attraction-counter {
    margin: 10px 0;
  }
}