/* src/components/AttractionRow.css */

.attraction-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.3s;
  }
  
  .attraction-row:hover {
    background-color: #f9f9f9;
  }
  
  .attraction-row.down {
    background-color: rgba(255, 0, 0, 0.1); /* Semi-transparent red */
  }
  
  .attraction-row.ridden {
    background-color: rgba(0, 128, 0, 0.1); /* Semi-transparent green */
  }
  
  .dark-mode .attraction-row.down {
    background-color: rgba(255, 0, 0, 0.2); /* Slightly brighter in dark mode */
  }
  
  .dark-mode .attraction-row.ridden {
    background-color: rgba(0, 128, 0, 0.2); /* Slightly brighter in dark mode */
  }
  
  .attraction-info h3 {
    margin: 0;
  }
  
  .attraction-info p {
    margin: 5px 0;
  }
  
  .status-down {
    color: #d8000c; /* Red color for the "down" status message */
    font-weight: bold;
  }
  
  .attraction-wait-time {
    text-align: right;
  }
  
  .icons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .favorite-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .ridden-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .price {
    color: green;
    font-weight: bold;
  }
  
  .sold-out {
    color: red;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .attraction-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .attraction-wait-time {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .icons {
      margin-top: 5px;
    }
  }